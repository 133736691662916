<template>
  <div class="jf-wid">
    <div class="jf-wid-14">
      <div class="jf-file-title">{{ $t("userInfo.layout.412518-0") }}</div>
      <div class="jf-user-layout">
        <div class="jf-user-left">
          <div class="jf-user-info">
            <div class="phone" v-if="userInfo">
              <img src="@/assets/pic/photo.webp" class="img" />
              <div class="info">
                <!-- <span>{{ $t("userInfo.layout.412518-1") }}</span> -->
                <h3>{{ userInfo.userName }}</h3>
                <span>{{ userInfo.levelName }}</span>
              </div>
            </div>
            <div class="money" v-if="accountMoney">
              <ul>
                <li>
                  <span>{{ $t("userInfo.layout.412518-2") }}</span>
                  <p>
                    {{
                      accountMoney.userBalance
                        ? accountMoney.userBalance.toFixed(2)
                        : "0.00"
                    }}
                  </p>
                </li>
                <li>
                  <span>{{ $t("userInfo.layout.412518-3") }}</span>
                  <p>
                    {{
                      accountMoney.frozenBalance
                        ? accountMoney.frozenBalance.toFixed(2)
                        : "0.00"
                    }}
                  </p>
                </li>
                <li>
                  <span>{{ $t("userInfo.layout.412518-4") }}</span>
                  <p>
                    {{
                      accountMoney.welfareCenterBalance
                        ? accountMoney.welfareCenterBalance.toFixed(2)
                        : "0.00"
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="jf-user-menu">
            <ul
              v-for="(item, index) in menus"
              :key="index"
              :class="{ room: index === 0 }"
            >
              <li
                v-for="(row, rowIndex) in item"
                :key="rowIndex"
                :class="{ hover: menusIndex === row.type }"
                @click="jumpLink(row)"
              >
                <span class="icon" :class="row.icon"></span>
                <span class="text">{{ row.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="jf-user-right">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menus: [
        [
          {
            icon: "icon-purse",
            name: this.$t("userInfo.layout.412518-5"),
            link: "",
            mode: 1,
          },
          {
            icon: "icon-round-transfer",
            name: this.$t("userInfo.layout.412518-6"),
            link: "",
            mode: 2,
          },
          {
            icon: "icon-qukuan",
            name: this.$t("userInfo.layout.412518-7"),
            link: "",
            mode: 3,
          },
        ],
        [
          {
            icon: "icon-user",
            name: this.$t("userInfo.layout.412518-8"),
            link: "/userInfo/index",
            type: 4,
          },
          {
            icon: "icon-vip",
            name: "VIP特权",
            link: "/user/home",
            type: 10,
          },
          {
            icon: "icon-card",
            name: this.$t("userInfo.layout.412518-9"),
            link: "/userInfo/bank",
            type: 10,
          },
          {
            icon: "icon-record",
            name: this.$t("userInfo.layout.412518-10"),
            link: "/userInfo/transaction",
            type: 5,
          },
          {
            icon: "icon-record",
            name: this.$t("userInfo.layout.412518-11"),
            link: "/userInfo/bet",
            type: 6,
          },
          {
            icon: "icon-free",
            name: this.$t("userInfo.layout.412518-4"),
            link: "/userInfo/welfare",
            type: 7,
          },
          {
            icon: "icon-message",
            name: this.$t("userInfo.layout.412518-12"),
            link: "/userInfo/message",
            type: 8,
          },
          {
            icon: "icon-earphone",
            name: this.$t("userInfo.layout.412518-13"),
            link: "/userInfo/feedback",
            type: 9,
          },
        ],
      ],
      menusIndex: 4,
    };
  },
  computed: {
    ...mapState(["accountMoney"]),
  },
  watch: {
    $route(to, from) {
      if (to.meta.routeMap !== from.meta.routeMap) {
        this.inItRouter();
      }
    },
  },
  mounted() {
    this.inItRouter();
  },
  methods: {
    inItRouter() {
      console.log(this.$route.meta.routeMap);
      this.menus[1].find((item) => {
        if (item.type === this.$route.meta.routeMap) {
          this.menusIndex = item.type;
        }
      });
    },
    jumpLink(val) {
      if (val.mode === 1) {
        this.judgeMoblieOrPayPwd(false, 1);
        return;
      }
      if (val.mode === 2) {
        this.$store.dispatch("setTransferAc", true);
        return;
      }
      if (val.mode === 3) {
        this.judgeMoblieOrPayPwd(false, 3);
        return;
      }
      if (val.link) {
        this.$router.push(val.link);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.jf-file-title {
  padding: 0.2rem 0;
  font-size: 0.16rem;
  font-weight: bold;
  color: white;
}
.jf-user {
  &-layout {
    display: flex;
    gap: 0.16rem;
    padding-bottom: 0.3rem;
  }
  &-left {
    display: flex;
    flex-direction: column;
    width: 2.67rem;
    gap: 0.16rem;
  }
  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.16rem;
  }
  &-info,
  &-menu {
    background: #31343b;
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    padding: 0.14rem;
    .phone {
      display: flex;
      align-items: center;
      gap: 0.1rem;
      border-bottom: 1px dashed #4f4f4f;
      padding-bottom: 0.16rem;
      .img {
        width: 0.48rem;
      }
      h3 {
        color: white;
        margin-top: 0.04rem;
      }
    }
    .money {
      width: 100%;
      padding: 0.1rem 0;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 0.04rem;
        }
        span {
          font-size: 0.12rem;
        }
        p {
          font-size: 0.16rem;
          color: white;
        }
      }
    }
  }
  &-menu {
    padding: 0 0.14rem;
    flex: 1;
    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      border-bottom: 1px dashed #4f4f4f;
      padding: 0.14rem 0;
      &:last-child {
        border-bottom: none;
      }
      &.room {
        flex-direction: row;
        justify-content: space-between;
        li {
          height: auto;
          flex-direction: column;
          gap: 0.06rem;
          padding: 0.14rem;
          .icon {
            font-size: 0.22rem;
          }
          .text {
            font-size: 0.14rem;
          }
        }
      }
    }
    li {
      display: flex;
      align-items: center;
      gap: 0.14rem;
      height: 0.52rem;
      font-size: 0.15rem;
      padding: 0 0.14rem;
      cursor: pointer;
      transition: all 0.4s;
      .icon {
        font-size: 0.18rem;
      }
      &.hover,
      &:hover {
        color: white;
        background: #292c33;
      }
    }
  }
}
</style>
